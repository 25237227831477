import { HomeOutlined, LogoutOutlined, MenuOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AdminMenu = ({ logoutFunction }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      label: (
        <div
          className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
          onClick={() => {
            navigate('/admin');
          }}
        >
          <HomeOutlined
            style={{
              color: location.pathname === "/admin" ? "#487ED8" : "#454545",
            }}
          />
          <span
            className="fs-6 ff-ws"
            style={{
              color: location.pathname === "/admin" ? "#487ED8" : "#454545",
            }}
          >
            Home
          </span>
        </div>
      ),
      key: "portal-credentials",
    },
    {
      label: (
        <div
          className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
          onClick={() => {
            navigate('/admin/generalSettings');
          }}
        >
          <SettingOutlined
            style={{
              color: location.pathname === "/admin/generalSettings" ? "#487ED8" : "#454545",
            }}
          />
          <span
            className="fs-6 ff-ws"
            style={{
              color: location.pathname === "/admin/generalSettings" ? "#487ED8" : "#454545",
            }}
          >
            Settings
          </span>
        </div>
      ),
      key: "settings",
    },
    {
      label: (
        <div
          className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
          onClick={() => logoutFunction()}
        >
          <span
            className="icon-btn_track"
            style={{
              height: "22px",
              // width: "30px",
              //   paddingLeft: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoutOutlined style={{ color: "#EF4444" }} />
          </span>
          <span className="fs-6 ff-ws" style={{ color: "#EF4444" }}>
            Logout
          </span>
        </div>
      ),
      key: "logout",
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      className=""
    >
      <MenuOutlined
        style={{ color: "#487ED8", fontSize: "26px" }}
        onClick={(e) => e.preventDefault()}
      />
    </Dropdown>
  );
};

export default AdminMenu;
