import React, { useState, useEffect } from "react";
import { ResizableBox } from "react-resizable";
import Draggable from "react-draggable";
import "react-resizable/css/styles.css";
import { DragOutlined, LeftOutlined } from "@ant-design/icons";
import {
  getComplianceReportDataAdminWithoutDate,
  getServicesByProfileId,
  onboardifyServiceReportAdminSetting,
  saveAdminServiceViewOnboardify,
} from "../apiservice/ApiService";
import { useLocation, useNavigate } from "react-router-dom";
import { BarChartHorizontal } from "../common/BarChartHorizontal";
import { BarChartVertical } from "../common/BarChartVertical";
import { Button } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { PieChart } from "../common/PieChart";
import { CustomTooltip } from "../common/CustomToolTip";
import { Loader } from "../common/Loader";


export const OnboardifyServiceReportAdminView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentData, setCurrentData] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [loading , setLoading] = useState(false);
  const [allColumnTitle, setAllColumnTitle] = useState([]);
  const [nameValue, setNameValue] = useState({
    currentName: "",
    previousName: "",
  });

  const [completeData, setCompleteData] = useState([]);
  const [containers, setContainers] = useState([
    {
      id: 1,
      boxes: [],
      height: 400,
    },
    {
      id: 2,
      boxes: [],
      height: 400,
    },
    {
      id: 3,
      boxes: [],
      height: 400,
    },
    {
      id: 4,
      boxes: [],
      height: 400,
    },
  ]);


  // Handle dragging stop for a specific box in a specific container
  const handleDragStop = (e, data, containerIndex, boxIndex) => {
    const newContainers = containers.map((container, i) =>
      i === containerIndex
        ? {
            ...container,
            boxes: container.boxes.map((box, j) =>
              j === boxIndex
                ? { ...box, position: { x: data.x, y: data.y } }
                : box
            ),
          }
        : container
    );
    setContainers(newContainers);
    // saveStateToSessionStorage(newContainers);
  };

  // Handle resizing for a specific box in a specific container
  const handleResize = (e, { size: newSize }, containerIndex, boxIndex) => {
    const newContainers = containers.map((container, i) =>
      i === containerIndex
        ? {
            ...container,
            boxes: container.boxes.map((box, j) =>
              j === boxIndex ? { ...box, size: newSize } : box
            ),
          }
        : container
    );

    setContainers(newContainers);
    // saveStateToSessionStorage(newContainers);
  };

  // Handle resizing of the container itself
  const handleContainerResize = (e, { size }, containerIndex) => {
    const newContainers = containers.map((container, i) =>
      i === containerIndex ? { ...container, height: size.height } : container
    );
    setContainers(newContainers);
    // saveStateToSessionStorage(newContainers);
  };

  // Toggle drag handle visibility on hover
  const toggleDragHandleVisibility = (containerIndex, boxIndex, visible) => {
    const newContainers = containers.map((container, i) =>
      i === containerIndex
        ? {
            ...container,
            boxes: container.boxes.map((box, j) =>
              j === boxIndex ? { ...box, showDragHandle: visible } : box
            ),
          }
        : container
    );
    setContainers(newContainers);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getServicesByProfileId(location.state.profileId);
      const response1 = await getComplianceReportDataAdminWithoutDate(
        location.state.boardId
        // 'creation_log__1'
      );

      let currentService = {};

      if (response.success) {
        response.data.response[0].services.length > 0 &&
          response.data.response[0].services.forEach((item) => {
            if (
              item.id === location.state.serviceId &&
              JSON.parse(item.onboardify_service_report) !== null
            ) {
              setContainers(JSON.parse(item.onboardify_service_report));
              currentService = JSON.parse(item.service_column_value_filter);
            }
          });
      }
      if (response1.success) {
        let tempCompleteData = [
          ...response1.data.response.data.boards[0].items_page.items,
        ];

        setCompleteData(tempCompleteData);
        setAllColumnTitle(response1.data.response.data.boards[0].columns);
        response1.data.response.data.boards[0].items_page.items.forEach(
          (item) => {
            if (currentService.key === "name") {
              if (
                item.name.toLowerCase() === currentService.value.toLowerCase()
              ) {
                let newTempColumns = [...item.column_values];
                newTempColumns.push({
                  id: "name",
                  text: item.name,
                  value: item.name,
                });
                setCurrentData(newTempColumns);
                setNameValue({ ...nameValue, currentName: item.name });
              }
            } else {
              item.column_values.forEach((subItem) => {
                if (
                  subItem.id === currentService.key &&
                  subItem.text === currentService.value
                ) {
                  let newTempColumns = [...item.column_values];
                  newTempColumns.push({
                    id: "name",
                    text: item.name,
                    value: item.name,
                  });
                  setCurrentData(newTempColumns);
                  setNameValue({ ...nameValue, currentName: item.name });
                }
              });
            }
          }
        );
      } else {
      }
    } catch (err) {
    } finally{
      setLoading(false);
    }
  };

  const delayFun = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const handleSubmit = async () => {
    const payloadData = {
      onboardify_service_report_view: JSON.stringify(containers),
      service_id: location.state.serviceId.toString(),
    };
    const payload1 = {
      service_id: location.state.serviceId.toString(),
      onboardify_service_report: JSON.stringify(containers),
    };
    setLoading(true);
    try {
      const response = await saveAdminServiceViewOnboardify(payloadData);
      await delayFun();
      const response1 = await onboardifyServiceReportAdminSetting(payload1);
      if (response.success && response1.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
    }finally{
      setLoading(false);

    }
  };

  const getColumnTitleForTextChart = (id) => {
    let tempValue = "";
    allColumnTitle.forEach((item) => {
      if (item.id === id) {
        tempValue = item.title;
      }
    });
    return tempValue;
  };

  const getStatusCountForValueChart = (key, value) => {
    let count = 0;
    completeData.forEach((item) => {
      item.column_values.forEach((subItem) => {
        if (subItem.id === key && subItem.text === value) {
          count = count + 1;
        }
      });
    });
    return count;
  };

  const getBgSquareColor = (id, data) => {
    let tempColor = "#000000";
    data.forEach((item) => {
      if (item.key === id) {
        tempColor = item.value;
      }
    });
    return tempColor;
  };

  const hexToRgba = (hex, opacity) => {
    // Remove the '#' if it's there
    hex = hex.replace("#", "");

    // Parse the hex color
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return the RGBA string with opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const getBgColorForBarChart = (subItem, item) => {
    let hexColor = "#d20e0e";
    subItem.selectedColor.forEach((detail) => {
      if (detail.key === item) {
        hexColor = detail.value;
      }
    });
    hexColor = hexToRgba(hexColor, "1");
    return hexColor;
  };

  const getChartValueData = (item, index) => {
    let value = 10;
    return value + index;
  };

  const getDataSetForVerticalBarChartStatus = (subItem) => {
    let tempData = [];
    subItem.selectedColumns.forEach((item, index) => {
      tempData.push({
        label: item,
        data: [getChartValueData(item, index)],
        backgroundColor: getBgColorForBarChart(subItem, item),
        borderColor: "transparent",
        borderWidth: 1,
        borderRadius: {
          topLeft: 5, // Set the top-left corner radius
          topRight: 5, // Set the top-right corner radius
          bottomLeft: 0, // No radius for the bottom-left corner
          bottomRight: 0, // No radius for the bottom-right corner
        },
        borderSkipped: false,
      });
    });

    return tempData;
  };

  const getDataSetForHorizontalBarChartStatus = (subItem) =>{
    let tempData = [];
    subItem.selectedColumns.forEach((item, index) => {
      tempData.push({
        label: item,
        data: [getChartValueData(item, index)],
        backgroundColor: getBgColorForBarChart(subItem, item),
        borderColor: "transparent",
        borderWidth: 1,
        borderRadius: {
          topLeft: 0, // Set the top-left corner radius
          topRight: 5, // Set the top-right corner radius
          bottomLeft: 0, // No radius for the bottom-left corner
          bottomRight: 5, // No radius for the bottom-right corner
        },
        borderSkipped: false,
      });
    });

    return tempData;
  }

  const calculateStepSizeStatus = (data) => {
    // Convert string data to numbers
    const numericData = data.map(Number);

    // Find min and max values in the data
    const minValue = Math.min(...numericData);
    const maxValue = Math.max(...numericData);

    // Calculate the range
    const range = maxValue - minValue;

    // Determine a reasonable number of steps (e.g., 5 or 10 steps)
    const numberOfSteps = 2; // You can adjust this for more/less granularity

    // Calculate the raw stepSize by dividing the range by number of steps
    let stepSize = range / numberOfSteps;

    // Round stepSize up to the nearest multiple of 10
    stepSize = Math.ceil(stepSize / 10) * 10;

    return stepSize;
  };

  const getStepSizeForVerticalBarChartStatus = (subItem) => {
    let value = 10;
    let tempData = [];
    subItem.selectedColumns.forEach((item, index) => {
      tempData.push(value + index);
    });

    let stepSize = calculateStepSizeStatus(tempData);

    return stepSize;
  };

  const getMaxForVerticalBarChartStatus = (subItem) => {
    let tempData = [];
    let value = 10;
    subItem.selectedColumns.forEach((item, index) => {
      tempData.push(value + index);
    });

    let chartMax = calculateChartMaxStatus(tempData);

    return chartMax;
  };

  const calculateChartMaxStatus = (data) => {
    const numericData = data.map(Number);

    // Find the maximum value in the data
    const maxValue = Math.max(...numericData);

    // Calculate the stepSize
    const stepSize = calculateStepSize(data);

    // Calculate the chart max value, which is one stepSize above the max value
    const chartMax = Math.ceil(maxValue / stepSize) * stepSize + stepSize;

    return chartMax;
  };

  const getTooltipDataStatus = (tempData) => {
    let tempCurrentArr = [];
    let tempPreviousArr = [];
    let value = 10;

    tempData.selectedColumns.forEach((item, index) => {
      tempCurrentArr.push({
        key: item,
        value: value + index,
      });
    });

    return { tempCurrentArr, tempPreviousArr };
  };

  const calculateStepSize = (data) => {
    // Convert string data to numbers
    const numericData = data.map(Number);

    // Find min and max values in the data
    const minValue = Math.min(...numericData);
    const maxValue = Math.max(...numericData);

    // Calculate the range
    const range = maxValue - minValue;

    // Determine a reasonable number of steps (e.g., 5 or 10 steps)
    const numberOfSteps = 2; // You can adjust this for more/less granularity

    // Calculate the raw stepSize by dividing the range by number of steps
    let stepSize = range / numberOfSteps;

    // Round stepSize up to the nearest multiple of 10
    stepSize = Math.ceil(stepSize / 10) * 10;

    return stepSize;
  };
 
  const getPieChartDataSetStatus = (subItem) => {
    let tempData = [];
    let value = 10;
    subItem.selectedColumns.forEach((item, index) => {
      tempData.push(value + index);
    });
    return tempData;
  };

  const getBgColorForBarChartStatus = (subItem, item) => {
    let hexColor = "#d20e0e";
    subItem.selectedColor.forEach((detail) => {
      if (detail.key === item) {
        hexColor = detail.value;
      }
    });
    hexColor = hexToRgba(hexColor, "1");
    return hexColor;
  };

  const getPieChartBgStatus = (subItem) => {
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push(getBgColorForBarChartStatus(subItem, item));
    });
    return tempData;
  };

  const getPieChartBorderStatus = (subItem) => {
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push("#fff");
    });
    return tempData;
  };

  const getPieChartLabelStatus = (subItem) => {
    let tempData = [];
    subItem.selectedColumns.forEach((item) => {
      tempData.push(item);
    });

    return tempData;
  };
  
  const getCountForStatusMultiValue = (column, subItem) => {
    let count = 0;
  
    subItem.selectedColumnsNewForStatus.forEach((detail) => {

        completeData.forEach((item) => {
          item.column_values.forEach((subDetail) => {
            if (subDetail.id === column && subDetail.text === detail) {
              count = count + 1;
            }
          });
        });
      
    });

    return count;
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ maxWidth: "1252px", marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        <Button icon={<LeftOutlined />} onClick={handleBackNavigation}></Button>
      </div>
      {loading && <Loader/>}
      {containers.map((container, containerIndex) => {
        return (
          <ResizableBox
            key={container.id}
            width={1252}
            height={Number(container.height)}
            minConstraints={[200, 150]}
            maxConstraints={[Infinity, Infinity]}
            resizeHandles={["s"]}
            onResizeStop={(e, data) =>
              handleContainerResize(e, data, containerIndex)
            }
            handle={
              <div
                style={{
                  width: "100%",
                  height: "10px",
                  backgroundColor: "#ccc",
                  cursor: "s-resize",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              ></div>
            }
            style={{
              marginBottom: "20px",
              border: "1px solid #ddd",
              padding: "10px",
              overflow: "auto",
              backgroundColor: "#f9f9f9",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#818181",
                  textAlign: "left",
                  marginBottom: "10px",
                }}
              >
                {container.title}
              </p>

              {container.boxes.map((subItem, boxIndex) => {
                if (subItem.type === "Value Chart") {
                 
                  return (
                    <Draggable
                      bounds="parent"
                      key={subItem.id}
                      handle=".drag-handle"
                      position={subItem.position}
                      grid={[25, 25]}
                      scale={1}
                      onStop={(e, data) =>
                        handleDragStop(e, data, containerIndex, boxIndex)
                      }
                    >
                      <div
                        style={{ position: "absolute" }}
                        onMouseEnter={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            true
                          )
                        }
                        onMouseLeave={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            false
                          )
                        }
                      >
                        <ResizableBox
                          width={Number(subItem.size.width)}
                          height={Number(subItem.size.height)}
                          minConstraints={[100, 100]}
                          maxConstraints={[500, 500]}
                          resizeHandles={["se"]}
                          onResizeStop={(e, data) =>
                            handleResize(e, data, containerIndex, boxIndex)
                          }
                          style={{
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            position: "relative",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {subItem.showDragHandle && (
                            <div
                              className="drag-handle"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#ccc",
                                cursor: "move",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderBottom: "1px solid black",
                                borderRight: "1px solid black",
                                zIndex: 1,
                              }}
                            >
                              <DragOutlined />
                            </div>
                          )}
                          <div style={{ width: "100%" }}>
                            <p
                              style={{
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#6d7175",
                                marginBottom: "6px",
                              }}
                            >
                              {subItem.column}{" "}
                              {getColumnTitleForTextChart(
                                subItem.selectedColumnsForStatus
                              )}
                            </p>
                            <p
                              style={{
                                textAlign: "left",
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#202223",
                              }}
                            >
                              {getStatusCountForValueChart(
                                subItem.selectedColumnsForStatus,
                                subItem.column
                              )}
                            </p>
                          </div>
                        </ResizableBox>
                      </div>
                    </Draggable>
                  );
                }
                if (subItem.type === "Pie Chart") {
                  return (
                    <Draggable
                      bounds="parent"
                      key={subItem.id}
                      handle=".drag-handle"
                      position={subItem.position}
                      grid={[25, 25]}
                      scale={1}
                      onStop={(e, data) =>
                        handleDragStop(e, data, containerIndex, boxIndex)
                      }
                    >
                      <div
                        style={{ position: "absolute" }}
                        onMouseEnter={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            true
                          )
                        }
                        onMouseLeave={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            false
                          )
                        }
                      >
                        <ResizableBox
                          width={subItem.size.width}
                          height={subItem.size.height}
                          minConstraints={[100, 100]}
                          maxConstraints={[
                            window.innerWidth - subItem.position.x,
                            1000,
                          ]}
                          resizeHandles={["se"]}
                          onResizeStop={(e, data) =>
                            handleResize(e, data, containerIndex, boxIndex)
                          }
                          style={{
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            position: "relative",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          {subItem.showDragHandle && (
                            <div
                              className="drag-handle"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#ccc",
                                cursor: "move",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderBottom: "1px solid black",
                                borderRight: "1px solid black",
                                zIndex: 1,
                              }}
                            >
                              <DragOutlined />
                            </div>
                          )}

                          <PieChart
                            title={subItem.heading}
                            dataset={getPieChartDataSetStatus(subItem)}
                            bgSet={getPieChartBgStatus(subItem)}
                            pieChartLabel={getPieChartLabelStatus(subItem)}
                            borderColorSetPie={getPieChartBorderStatus(subItem)}
                            description={subItem.description}
                            width={subItem.size.width} // Pass the updated width
                            height={subItem.size.height} // Pass the updated height
                            mobileView={false}
                          />
                        </ResizableBox>
                      </div>
                    </Draggable>
                  );
                }
                if (subItem.type === "Multi Value Chart") {
                  return (
                    <Draggable
                      bounds="parent"
                      key={subItem.id}
                      handle=".drag-handle"
                      position={subItem.position}
                      grid={[25, 25]}
                      scale={1}
                      onStop={(e, data) =>
                        handleDragStop(e, data, containerIndex, boxIndex)
                      }
                    >
                      <div
                        style={{ position: "absolute" }}
                        onMouseEnter={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            true
                          )
                        }
                        onMouseLeave={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            false
                          )
                        }
                      >
                        <ResizableBox
                          width={Number(subItem.size.width)}
                          height={Number(subItem.size.height)}
                          minConstraints={[100, 100]}
                          maxConstraints={[Infinity, Infinity]}
                          resizeHandles={["se"]}
                          onResizeStop={(e, data) =>
                            handleResize(e, data, containerIndex, boxIndex)
                          }
                          style={{
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            position: "relative",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "44px",
                          }}
                        >
                          {subItem.showDragHandle && (
                            <div
                              className="drag-handle"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#ccc",
                                cursor: "move",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1,
                              }}
                            >
                              <DragOutlined />
                            </div>
                          )}

                          <div
                            style={{
                              width: "70%",
                              borderBottom:
                                "1px solid rgba(201, 204, 207, 0.7)",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "24px",
                                fontWeight: "700",
                                color: "#202223",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                                width: "90%",
                              }}
                            >
                              {subItem.heading}
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip
                                    description={subItem.description}
                                  />
                                )}
                              </span>
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "26px",
                              flexDirection: "column",
                              width: "70%",
                            }}
                          >
                            {subItem.selectedColumnsNewForStatus.map((column, index) => (
                              <div key={index} style={{ marginBottom: "10px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "24px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      background: getBgSquareColor(
                                        column,
                                        subItem.selectedColor
                                      ),
                                      borderRadius: "4px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      color: "#202223",
                                      fontSize: "20px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {column}
                                
                                  </div>
                                </div>
                                <p
                                  style={{
                                    fontSize: "45px",
                                    fontWeight: "700",
                                    color: "#202223",
                                  }}
                                >
                                  {getCountForStatusMultiValue(subItem.selectedColumns, subItem)}
                                </p>
                                {subItem.selectedColumnsNewForStatus.length - 1 > index && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgba(201, 204, 207, 0.7)",
                                    }}
                                  ></div>
                                )}
                              </div>
                            ))}
                          </div>
                        </ResizableBox>
                      </div>
                    </Draggable>
                  );
                }
                if (subItem.type === "Bar Chart") {
                  return (
                    <Draggable
                      bounds="parent"
                      key={subItem.id}
                      handle=".drag-handle"
                      position={subItem.position}
                      grid={[25, 25]}
                      scale={1}
                      onStop={(e, data) =>
                        handleDragStop(e, data, containerIndex, boxIndex)
                      }
                    >
                      <div
                        style={{ position: "absolute" }}
                        onMouseEnter={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            true
                          )
                        }
                        onMouseLeave={() =>
                          toggleDragHandleVisibility(
                            containerIndex,
                            boxIndex,
                            false
                          )
                        }
                      >
                        <ResizableBox
                          width={Number(subItem.size.width) || 500}
                          height={Number(subItem.size.height) || 500}
                          minConstraints={[100, 100]}
                          maxConstraints={[
                            window.innerWidth - subItem.position.x,
                            1000,
                          ]}
                          resizeHandles={["se"]}
                          onResizeStop={(e, data) => {
                            handleResize(e, data, containerIndex, boxIndex);
                          }}
                          style={{
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            position: "relative",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          {subItem.showDragHandle && (
                            <div
                              className="drag-handle"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#ccc",
                                cursor: "move",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderBottom: "1px solid black",
                                borderRight: "1px solid black",
                                zIndex: 1,
                              }}
                            >
                              <DragOutlined />
                            </div>
                          )}

                          {subItem.horizontal ? (
                            <BarChartHorizontal
                              dataset={getDataSetForHorizontalBarChartStatus(
                                subItem
                              )}
                              stepsize={getStepSizeForVerticalBarChartStatus(
                                subItem
                              )}
                              max={getMaxForVerticalBarChartStatus(subItem)}
                              title={subItem.heading}
                              description={subItem.description}
                              toolTipData={getTooltipDataStatus(subItem)}
                              previousData={previousData}
                              mobileView={false}
                            />
                          ) : (
                            <BarChartVertical
                              dataset={getDataSetForVerticalBarChartStatus(
                                subItem
                              )}
                              stepsize={getStepSizeForVerticalBarChartStatus(
                                subItem
                              )}
                              max={getMaxForVerticalBarChartStatus(subItem)}
                              title={subItem.heading}
                              description={subItem.description}
                              toolTipData={getTooltipDataStatus(subItem)}
                              previousData={previousData}
                              mobileView={false}
                            />
                          )}
                        </ResizableBox>
                      </div>
                    </Draggable>
                  );
                }
              })}
            </div>
          </ResizableBox>
        );
      })}

      <div>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};
