export const countryList = [
  {
    code: "SA",
    name: "Saudi Arabia",
    countryCode:'KSA'
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    countryCode:'UAE'
  },
  {
    code: "AQ",
    name: "Rest of Gulf",
    countryCode:'ROG'
  },
];
